<template>
  <div>
      <div class="content" @click.stop="chooseListMethod(item,index)" :style="{borderColor:cur==index?isSkin:'#DDD'}">
          <div class="title">
              <div class="content-view">
                  <div class="name">{{item.name}}</div>
                  <div class="phone">{{item.phone}}</div>
                  <div class="isDefault" v-if="item.isDefault==0" :style="{backgroundColor:isSkin}">默认地址</div>
              </div>
              <div class="address">{{item.address}}</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:{
            type:Number,
            default:0
        },
        cur:{
            type:Number,
            default:0
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
    },
    methods:{
        chooseListMethod(item,index){
            this.$emit('chooseListMethod',item,index)
        }
    }
}
</script>

<style scoped lang="scss">
.content{
    width: 100%;
    height: 60px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    .title{
        width: 75%;
        height: 50px;
        margin-left: 20px;
        .content-view{
            display: flex;
            justify-items: center;
            align-items: center;
            height: 20px;
            margin-top: 5px;
            .name{
                font-size: 14px;
                line-height: 20px;
                color: #333333;
            }
            .phone{
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                margin-left: 10px;
            }
            .isDefault{
                width: 68px;
                height: 21px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 21px;
                text-align: center;
                color: #FFFFFF;
                margin-left: 10px;
            }
        }
        .address{
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            color: #666666;
            margin-top: 5px;
        }
    }
}
.active{
    border: 1px solid isSkin;
}
.active1{
    border: 1px solid #DDD;
}
</style>
<template>
  <div class="main-sample">
    <div class="content-view">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">申请样书</div>
      </div>
      <!--  图书信息    -->
      <div class="info">
        <div class="info1">
          <div class="headImg">
            <img :src="itemData.img" alt="">
          </div>
          <div class="right">
            <div class="name">{{'《'+itemData.name+'》'}}</div>
            <div class="opus">{{itemData.opus+'  '+'著'}}</div>
            <div class="price">{{'¥'+itemData.price}}</div>
          </div>
        </div>
        <div class="info2">
          <div class="right1">
            <div class="nr">
              <div class="mz" v-html="'书&emsp;&emsp;名：'"></div>
              <div class="mz-nr">{{itemData.name}}</div>
            </div>
            <div class="nr">
              <div class="mz" v-html="'I&nbsp S  B&nbsp N：'"></div>
              <div class="mz-nr">{{itemData.bookNumber}}</div>
            </div>
            <div class="nr">
              <div class="mz" v-html="'印刷方式：'"></div>
              <div class="mz-nr">{{itemData.printing}}</div>
            </div>
          </div>
          <div class="right2">
            <div class="nr">
              <div class="mz" v-html="'责任编辑：'"></div>
              <div class="mz-nr">{{itemData.compile}}</div>
            </div>
            <div class="nr">
              <div class="mz" v-html="'定&emsp;&emsp;价：'"></div>
              <div class="mz-nr">{{itemData.price}}</div>
            </div>
            <div class="nr">
              <div class="mz" v-html="'开&emsp;&emsp;本：'"></div>
              <div class="mz-nr">{{itemData.bookSize}}</div>
            </div>
          </div>
          <div class="right3">
            <div class="nr">
              <div class="mz" v-html="'出版日期：'"></div>
              <div class="mz-nr">{{itemData.publishDate}}</div>
            </div>
            <div class="nr">
              <div class="mz" v-html="'页&emsp;&emsp;数：'"></div>
              <div class="mz-nr">{{itemData.pages}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--   申请信息   -->
      <div class="applyInfo">
        <div class="top-title">
          <div class="top1" :style="{backgroundColor:isSkin}"></div>
          <div class="top2">申请信息</div>
          <div class="top3">（必填）</div>
        </div>
        <div class="srnr">
          <div class="srnr1">
            <!--样书类型-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'样书类型：'"></div>
              <div class="inputTitle">
                <selectList :list="list" :placeholder="placeholder" @chooseMethod="chooseMethod"></selectList>
              </div>
            </div>
            <!--姓名-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'姓&emsp;&emsp;名：'"></div>
              <div>
                <input class="input-view" v-model="name" placeholder="请输入姓名">
              </div>
            </div>
            <!--电话-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'电&emsp;&emsp;话：'"></div>
              <div>
                <input class="input-view" v-model="phone" placeholder="请输入电话">
              </div>
            </div>
            <!--邮箱-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'邮&emsp;&emsp;箱：'"></div>
              <div>
                <input class="input-view" v-model="mail" placeholder="请输入邮箱">
              </div>
            </div>
            <!--地址-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'地&emsp;&emsp;址：'"></div>
              <div class="shuruk">
                <div class="dz-nr" v-show="isDZ">
                  <div class="nr1">
                    <div class="nr-t">{{resData.name}}</div>
                    <div class="nr-p">{{resData.phone}}</div>
                  </div>
                  <div class="nr2">{{resData.address}}</div>
                </div>
                <div class="xz" v-show="!isDZ" @click.stop="DZMethod()">选择地址</div>
                <div class="gh" v-show="isDZ" @click.stop="DZMethod()" :style="{color:isSkin}">更换地址</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--   其他信息   -->
      <div class="qitaInfo">
        <div class="top-title">
          <div class="top1" :style="{backgroundColor:isSkin}"></div>
          <div class="top2">其他信息</div>
          <div class="top3">（选填）</div>
        </div>
        <div class="srnr">
          <div class="srnr1">
            <!--姓名-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'发  行&nbsp 号：'"></div>
              <div>
                <input class="input-view" v-model="issueNumber" placeholder="请输入发行号">
              </div>
            </div>
            <!--电话-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'授课名称：'"></div>
              <div>
                <input class="input-view" v-model="teachingName" placeholder="请输入授课名称">
              </div>
            </div>
            <!--邮箱-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'目前选用教材：'"></div>
              <div>
                <input class="input-view" v-model="teachingMaterial" placeholder="请输入选用教材">
              </div>
            </div>

            <!--有无出版意向-->
            <div class="srnr1-one">
              <div class="tm-title" v-html="'有无出版意向：'"></div>
              <div>
                <el-radio v-model="radio" @change="changeMethod" label="1">无</el-radio>
                <el-radio v-model="radio" @change="changeMethod" label="2">有</el-radio>
              </div>
            </div>
            <!--地址-->
            <div class="srnr2-one">
              <div class="tm-title" v-html="'备&emsp;&emsp;注：'"></div>
                <el-input class="textarea"
                    type="textarea"
                    :rows="5"
                    placeholder="请输入内容"
                    v-model="txt">
                </el-input>
            </div>
          </div>
          <div class="tijiao" :style="{backgroundColor:isSkin}" @click.stop="sureMethod()">提交</div>
        </div>
      </div>
    </div>
    <!-- 选择地址 -->
    <el-dialog title="修改密码" class="dialog" :width="'500px'" :visible.sync="isShow" :close-on-click-modal=false>
      <Address v-for="(item,index) in list1" :key="index" :index="index" :cur="cur" :item="item" @chooseListMethod="chooseListMethod"></Address>
      <div class="page" v-show="pageShow">
        <div class="page1">
          <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <div class="sure" :style="{backgroundColor:isSkin}" @click="changPwdMethod()">确认</div>
    </el-dialog>
  </div>
</template>

<script>
import Address from './components/chooseRess-box';
import {Message} from 'element-ui'
import selectList from '../../users/components/box/select-box'
export default {
  name: "sampleApplication.vue",
  components:{selectList,Address},
  data(){
    return{
      pageShow:false,
      cur:0,
      totals:1,
      currentPage:1,
      pageSize:10,
      isShow:false,
      itemData:{},
      bookID:'',
      radio:'1',
      placeholder:'请选择',
      list:[{
        dictLabel:'纸质图书',
        dictValue:1
      },{
        dictLabel:'电子样书',
        dictValue:1
      }],
      booksType:'',
      name:'',
      phone:'',
      mail:'',
      txt:'',
      issueNumber:'',
      teachingName:'',
      teachingMaterial:'',
      address:'',
      addressName:'',
      addressPhone:'',
      isDZ:false,
      list1:[],
      resData:{}
    }
  },
  created(){
    this.bookID = this.$route.query.bookID
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
      this.$store.commit('changSkin',skin)
    }
    if (zskin) {
      this.$store.commit('changZSkin',zskin)
    }
    this.getBookSDetails()
    this.changeMethod(this.radio)
    this.getMemberAddress()
  },
  computed:{
    isSkin(){
      return this.$store.state.isSkin
    },
  },
  methods:{
    /**地址列表**/
    getMemberAddress(){
      var that = this
      that.$api.getMemberAddress({
        params:{
          pageNum:that.currentPage,
          pageSize:that.pageSize
        }
      }).then(res=>{
        if (res.data.code == 0) {
          that.list1 = res.data.data.list
          that.totals = res.data.data.pages
          that.resData = res.data.data.list[0]
          that.address = that.resData.address
          that.addressName = that.resData.name
          that.addressPhone = that.resData.phone
          if (res.data.data.list.length == 0){
            that.pageShow = false
          }else {
            that.pageShow = true
          }
        }else{
          that.pageShow = false
          that.list = []
          that.resData = {}
          that.address = ''
          that.addressName = ''
          that.addressPhone = ''
        }
        if (this.isEmptyObject(this.resData)) {
          this.isDZ = false
        }else{
          this.isDZ = true
        }
      })
    }, 
    /**判断对象是否为空**/ 
    isEmptyObject(obj) {
      for (var key in obj) {
        return false;
      }
      return true;
    },
    DZMethod(){
      this.isShow = true
      this.handleCurrentChange(1)
    },
    changPwdMethod(){
      this.isShow = false
    },
    chooseListMethod(item,index){
      this.cur = index
      this.resData = item
      this.address = this.resData.address
      this.addressName = this.resData.name
      this.addressPhone = this.resData.phone
    },
    /**类型选择按钮**/
    chooseMethod(index){
      this.booksType = index
      this.placeholder = ''
    },
    /**提交按钮**/
    sureMethod(){
      var that = this
      if (that.booksType == '') {
        Message({
          showClose: true,
          message: '请选择样书类型！',
          type: 'error'
        })
        return
			}
      if (that.name == '') {
        Message({
          showClose: true,
          message: '请输入姓名！',
          type: 'error'
        })
        return
			}
      if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入电话！',
          type: 'error'
        })
        return
			}
      if (that.mail == '') {
        Message({
          showClose: true,
          message: '请输入邮箱！',
          type: 'error'
        })
        return
			}
      if (that.address == '') {
        Message({
          showClose: true,
          message: '请选择地址！',
          type: 'error'
        })
        return
			}
      that.getBooksApply()
    },
    /**获取图书详情**/
    getBookSDetails(){
      var that = this
      that.$api.getBooksDetails({
        params:{
          id:that.bookID,
        }
      }).then(res=>{
        if (res.data.code == 0) {
          that.itemData = res.data.data
        }else{
          that.itemData = {}
        }
      })
    },
    /**申请样书**/
    getBooksApply(){
      let that = this
      that.$api.getBooksApply({
        booksId:that.bookID,
        booksType:that.booksType,
        address:that.address,
        consigneeName:that.addressName,
        consigneePhone:that.addressPhone,
        mailbox:that.mail,
        isIntention:that.radio,
        issueNumber:that.issueNumber,
        phone:that.phone,
        rname:that.name,
        remarks:that.txt,
        teachingMaterial:that.teachingMaterial,
        teachingName:that.teachingName
      }).then(res=>{
        if (res.data.code == 0){
          Message({
            showClose: true,
            message: '提交申请成功！',
            type: 'success'
          })
          setTimeout(()=>{
            this.$router.go(-1)
          },2000)
        }else {
          Message({
            showClose: true,
            message: '提交申请失败！',
            type: 'error'
          })
        }
      })
    },
    /**当前页按钮**/ 
    handleCurrentChange(index) {
      console.log(index);
      this.currentPage = index;
      this.$nextTick(function () {
        /**更换分页按钮皮肤**/
        var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
        if(array && array.length>0){
          array.forEach((item,index1) => {
            if (index == array[index1].innerText) {
              array[index1].style.background  = this.isSkin;
            }else{
              array[index1].style.background  = "#f4f4f5";
            }
          })
        }
      });
    },
    /**单项选择**/
    changeMethod(index){
      this.radio = index
      if (index == 1) {
          /**改变未选中时背景颜色**/
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__inner");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = "#FFF";
                array[index1].style.borderColor  = "#DDD";
              })
            }
          });
          /**改变选中时背景颜色**/
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__input.is-checked .el-radio__inner, .el-radio__input.is-indeterminate .el-radio__inner");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = this.isSkin;
                array[index1].style.borderColor  = this.isSkin;
              })
            }
          });
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__input.is-checked+.el-radio__label ");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.color  = "#666";
              })
            }
          });
      }else if (index == 2){
          /**改变未选中时背景颜色**/
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__inner");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = "#FFF";
                array[index1].style.borderColor  = "#DDD";
              })
            }
          });
          /**改变选中时背景颜色**/
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__input.is-checked .el-radio__inner, .el-radio__input.is-indeterminate .el-radio__inner");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.backgroundColor  = this.isSkin;
                array[index1].style.borderColor  = this.isSkin;
              })
            }
          });
          this.$nextTick(function () {
            var array = document.querySelectorAll(".el-radio__input.is-checked+.el-radio__label ");
            if(array && array.length>0){
              array.forEach((item,index1) => {
                array[index1].style.color  = "#666";
              })
            }
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-sample{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-view{
    width: 1200px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      margin-top: 10px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
    .info{
      width: 100%;
      height: 130px;
      display: flex;
      justify-content: left;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 60px;
      .info1{
        width: 416px;
        height: 100%;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        justify-content: left;
        align-items: center;
        .headImg{
          width: 90px;
          height: 90px;
          > img{
            width: 100%;
            height: 100%;
          }
        }
        .right{
          height: 100%;
          width: 250px;
          margin-left: 20px;
          .name{
            font-size: 16px;
            height: 25px;
            line-height: 25px;
            color: #333333;
          }
          .opus{
            font-size: 12px;
            line-height: 17px;
            color: #999999;
            margin-top: 5px;
          }
          .price{
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #FE2929;
            margin-top: 10px;
          }
        }
      }
      .info2{
        width: 745px;
        height: 100%;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        margin-left: 40px;
        padding: 20px;
        .right1{
          width: 100%;
          height: calc(100% / 3);
          display: flex;
          justify-content: left;
          align-items: center;
          .nr{
            height: 100%;
            width: calc(100% / 3);
            display: flex;
            justify-content: left;
            align-items: flex-start;
            .mz{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #999999;
            }
            .mz-nr{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
        }
        .right2{
          width: 100%;
          height: calc(100% / 3);
          display: flex;
          justify-content: left;
          align-items: center;
          .nr {
            height: 100%;
            width: calc(100% / 3);
            display: flex;
            justify-content: left;
            align-items: center;
            .mz{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #999999;
            }
            .mz-nr{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
        }
        .right3{
          width: 100%;
          height: calc(100% / 3);
          display: flex;
          justify-content: left;
          align-items: center;
          .nr {
            height: 100%;
            width: calc(100% / 3);
            display: flex;
            justify-content: left;
            align-items: flex-end;
            .mz{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #999999;
            }
            .mz-nr{
              font-size: 14px;
              line-height: 20px;
              text-align: justify;
              color: #333333;
            }
          }
        }
      }
    }
    .applyInfo{
      width: 100%;
      .top-title{
        display: flex;
        justify-content: left;
        align-items: flex-start;
        .top1{
          width: 7px;
          height: 20px;
        }
        .top2{
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          margin-left: 5px;
        }
        .top3{
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #333333;
          margin-top: 5px;
        }
      }
      .srnr{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .srnr1{
          width: 420px;
          .srnr1-one{
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            .tm-title{
              width: 105px;
              text-align: right;
            }
            .shuruk{
              width: 310px;
              background: #FCFCFC;
              border: 1px solid #DDDDDD;
              box-sizing: border-box;
              border-radius: 4px;
              min-height: 40px;
              padding: 5px;
              .dz-nr{
                padding: 5px;
                .nr1{
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  .nr-t{
                    font-size: 14px;
                    line-height: 20px;
                    color: #333333;
                  }
                  .nr-p{
                    margin-left: 10px;
                    font-size: 12px;
                    line-height: 17px;
                    color: #999999;
                  }
                }
                .nr2{
                  margin-top: 5px;
                  font-size: 12px;
                  line-height: 17px;
                  color: #666666;
                }
              }
              .gh{
                width: 280px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                margin-left: 240px;
                cursor: pointer;
              }
              .xz{
                width: 280px;
                height: 30px;
                line-height: 30px;
                font-size: 15px;
                color: #666666;
                margin-left: 10px;
                cursor: pointer;
              }
            }
            .inputTitle{
              width: 310px;
              height: 40px;
            }
          }
        }
      }
    }
    .qitaInfo{
      width: 100%;
      margin-top: 40px;
      .top-title{
        display: flex;
        justify-content: left;
        align-items: flex-start;
        .top1{
          width: 7px;
          height: 20px;
        }
        .top2{
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          color: #333333;
          margin-left: 5px;
        }
        .top3{
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #333333;
          margin-top: 5px;
        }
      }
      .srnr {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .srnr1 {
          width: 420px;
          .srnr1-one {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            .tm-title{
              width: 105px;
              text-align: right;
            }
            .shuruk {
              width: 310px;
              background: #FCFCFC;
              border: 1px solid #DDDDDD;
              box-sizing: border-box;
              border-radius: 4px;
              min-height: 40px;
              padding: 5px;

              .xz {
                height: 30px;
                line-height: 30px;
                font-size: 15px;
                color: #666666;
              }
            }
            .inputTitle {
              width: 310px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
.srnr2-one {
  width: 420px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  .tm-title {
    width: 105px;
    text-align: right;
  }
  .textarea {
    width: 310px;
    height: 120px;
    padding:20px;
  }
}
.tijiao{
  width: 387px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 110px;
  margin-bottom: 200px;
  cursor: pointer;
}
.input-view{
  width: 310px;
  height: 40px;
  background: #FCFCFC;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 15px;
  line-height: 40px;
  color: #666666;
  outline: none;
}
.page{
  width: 500px;
  padding: 40px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page1{
    display: flex;
    justify-items: center;
    align-items: center;
  }
}
.sure{
  width: 80px;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #FFFFFF;
  text-align: center;
  margin-left: 360px;
  cursor: pointer;
}
</style>